// ----------------------------------------
// Phone navigation bar

.sm-navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $color--white;
	position: relative;
	z-index: 20;

	@include media('>=md') { display: none; }

	&__logo-wrapper { padding-top: 4px; }

	&__logo {
		display: inline-block;
		width: 44px;
		height: 38px;
		overflow: hidden;

		&::before {
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background-image: brand('brandmark.svg');
			background-size: 100% 100%; }
	}
}
