// ----------------------------------------
// Article

.article {
	color: $color--dark;

	&__title {
		font: 700 21px / 24px $font--text;

		@include media('>=md') {
			margin: 7px 0 40px 0;
			font: 700 42px / 48px $font--text; }
	}

	&__info-block {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 35px 0 25px 0;
		padding-bottom: 27px;
		border-bottom: 1px solid $color--white;
		letter-spacing: 0.03em;

		@include media('>=md') {
			flex-direction: row;
			margin: 74px 0 25px 0; }
	}

	&__info-block-primary {
		display: flex;
		flex-direction: column;

		@include media('>=md') { flex-direction: row; }
	}

	&__info-author {
		font: 400 16px / 1 $font--text;

		@include media('>=md') { margin-right: 31px; }
	}

	&__info-block-contact {
		display: flex;
		align-items: center; }

	&__info-twitter {
		display: inline-block;
		width: 17px;
		height: 15px;
		overflow: hidden;

		svg {
			display: block;
			width: 100%;
			height: 100%; }

		svg * { fill: $color--red; }
	}

	&__info-email {
		margin-left: 12px;
		font: 400 16px / 1 $font--text;
		color: $color--red;
		margin: 8px 0 8px 12px;

		@include media('>=md') { margin: 0 0 0 12px; }
	}

	&__info-time { font: 400 16px / 1 $font--text; }

	&__rte {

		h3 {
			margin: 40px 0 20px 0;
			font: 700 16px / 1 $font--text;

			@include media('>=md') { font: 700 28px / 1 $font--text; }

			&:first-of-type { margin-top: 0; }
		}

		h4 {
			padding: 27px 0 19px 0;
			font: 400 16px / 1 $font--text;
			letter-spacing: 0.03em;

			@include media('>=md') { font: 400 16px / 1 $font--text; }
		}

		h5 {
			margin: 10px 0;
			font: 300 21px / 1 $font--text;
			letter-spacing: 0.03em; }

		p {
			margin: 16px 0 31px 0;
			padding: 0;
			font: 300 16px / 22px $font--text;

			@include media('>=md') {font: 300 21px / 29px $font--text; }
		}

		a {
			color: $color--red;

			&:hover { border-bottom: 1px solid $color--red;	}
		}

		table {
			width: 100%;
			margin-bottom: 18px;
			border-collapse: collapse;
			letter-spacing: 0.03em; }

		thead {
			background-color: $color--blue;

			th {
				padding: 8px 12px;
				font: 400 12px / 18px $font--text;
				text-transform: uppercase;
				text-align: center;
				color: $color--brightest;

				@include media('>=md') {
					padding: 20px 12px;
					font: 400 12px / 1 $font--text; }

				&:first-of-type {
					text-align: left;

					@include media('>=md') { padding-left: 23px; }
				}
			}
		}

		tbody {
			background-color: $color--white;

			td {
				padding: 12px 8px 12px 8px;
				border-top: 1px solid $color--brightest;
				border-left: 1px solid $color--brightest;
				font: 400 12px / 18px $font--text;
				text-align: center;
				color: $color--dark;

				@include media('>=md') {
					padding: 19px 12px 20px 12px;
					font: 400 16px / 1 $font--text; }

				&:nth-child(n+3) {
					@include media('>=md') { width: 175px; }
				}

				&:first-of-type {
					border-left: 0;
					text-align: left;
					color: $color--red; }
			}

			tr:first-of-type td { border-top: 0; }

			tr:hover td {
				border-left-color: #b84f28;
				background-color: $color--red;
				color: $color--white;
				cursor: pointer;

				&:first-of-type { text-decoration: underline; }
			}
		}

		hr {
			display: block;
			width: 100%;
			height: 1px;
			margin: 25px 0;
			border: none;
			background-color: $color--white; }

		figure {
			margin: 120px 0;
			padding: 0;

			img {
				display: block;
				width: 100%; }

			figcaption {
				margin: 12px 0;
				padding-left: 22px;
				font: 400 12px / 1 $font--text;
				letter-spacing: 0.05em;
				position: relative;

				&::before {
					display: inline-block;
					width: 8px;
					height: 13px;
					content: '';
					margin-right: 15px;
					background-color: $color--dark;
					transform: skewX(25deg);
					position: absolute;
					top: 0;
					left: 5px; }
			}
		}
	}

	&__form-group {
		display: flex;
		flex-direction: column;

		@include media('>=md') { flex-direction: row; }
	}

	&__form-item { flex: 0 1 50%; }

	&__table-header {
		display: flex;
		flex-direction: column;
		margin: 48px 0 20px 0;
		border-top: 1px solid $color--white;

		@include media('>=md') {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0; }
	}

	&__table-footer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		@include media('>=md') { flex-direction: row; }
	}

	&__small {
		margin: 15px 0;
		font: 400 12px / 1 $font--text;

		@include media('>=md') { margin: 0; }
	}

	&__pagination {
		width: 100%;

		@include media('>=md') { width: auto; }
	}

	&__pagination-list {
		display: flex;
		justify-content: space-between; }

	&__pagination-item {
		margin: 0 5px;
		font: 400 16px / 1 $font--text;
		color: $color--dark;

		@include media('>=md') { margin: 0 10px; }
	}

	&__pagintion-link { color: $color--red; }

	&__legend {
		margin: 117px 0 0 0;

		dl {
			display: flex;
			margin: 0;
			padding: 0;
			background-color: $color--bright;
			letter-spacing: 0.05em;
			color: $color--white; }

		dt {
			flex: 0 1 25%;
			padding: 15px;
			border-top: 1px solid #adadad;
			border-right: 1px solid #adadad;
			font: 700 12px / 14px $font--text;

			@include media('>=md') {
				flex: 0 1 20%;
				padding: 17px 22px;
				font: 700 16px / 19px $font--text; }
		}

		dd {
			flex: 0 1 75%;
			margin: 0;
			padding: 12px 18px;
			border-top: 1px solid #adadad;
			font: 300 12px / 18px $font--text;

			@include media('>=md') {
				flex: 0 1 80%;
				padding: 15px 22px;
				font: 300 16px / 23px $font--text; }
		}

		dl:first-of-type dt,
		dl:first-of-type dd { border-top: none; }

	}

	&__legend-header {
		display: flex;
		justify-content: space-between;
		padding: 18px 15px 20px 15px;
		background-color: $color--dark;

		@include media('>=md') { padding: 18px 22px 20px 22px; }
	}

	&__legend-title {
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		color: $color--brightest; }

	&__legend-info {
		font: 400 12px / 1 $font--text;
		color: $color--white; }
}
