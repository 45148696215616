// ----------------------------------------
// Team

.team {
	width: calc(100% + 30px);
	margin: 48px 0 20px 0;
	position: relative;
	left: -15px;

	@include media('>=md') {
		width: auto;
		position: static; }

	&__group { margin-bottom: 20px; }

	&__title {
		font: 400 49px / 1 $font--head;
		text-transform: uppercase;
		text-align: center;
		color: $color--white;

		@include media('>=md') {
			font-size: 80px;
			text-align: left; }
	}

	&__players {
		display: flex;
		margin-top: 28px; }

	&__position {
		flex: 0 1 50%;
		margin: 0 5px;
		position: relative;

		&:focus { outline: none; }

		@include media('>=md') { margin: 0; }

		&--left { flex-basis: 32%; }
		&--center { flex-basis: 36%; }
		&--right { flex-basis: 32%; }
	}

	&__shirt-wrapper {
		width: 100%;
		height: 247px;
		margin: 0 10px;
		position: relative; }

	&__group--pair &__shirt-wrapper { margin: 0; }

	&__shirt {
		width: 100%;
		height: 100%;
		background-image: icon('shirt.svg');
		background-repeat: no-repeat;
		background-position: center 10%;
		background-size: contain;
		position: absolute;
		left: 50%;
		transform: translateX(-50%); }

	&__position--center &__shirt-wrapper { width: calc(100% - 20px); }

	&__position--wing &__shirt-wrapper {
		width: calc(100% - 20px);

		@include media('>=xl') { padding-top: 16px; }
	}

	&__shirt-name {
		font: 400 20px / 1 $font--sport;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		color: $color--white;
		position: absolute;
		top: 18%;
		left: 50%;
		transform: translateX(-50%);

		@include media('>=xl') { font-size: 23px; }
	}

	&__position--wing &__shirt-name { font-size: 20px; }

	&__shirt-number {
		font: 400 93px / 1 $font--sport;
		color: $color--black;
		position: absolute;
		top: 25%;
		left: 50%;
		transform: translateX(-50%);

		@include media('>=xl') { font-size: 108px; }
	}

	&__position--wing &__shirt-number { font: 400 93px / 1 $font--sport; }

	&__position-name {
		padding: 15px 0;
		background-color: $color--white;
		font: 700 16px / 1 $font--text;
		text-transform: uppercase;
		text-align: center;
		color: $color--blue;

		&::before {
			display: block;
			width: 100%;
			height: 170px;
			content: '';
			background-color: $color--white;
			position: absolute;
			top: 85px;
			left: 0;
			z-index: -1; }
	}

	&__group--pair &__position-name { padding-top: 25px; }

	&__players-list {}

	&__players-list-item {
		padding: 19px 10px;
		background-color: $color--navy;
		font: 400 16px / 1 $font--text;
		letter-spacing: 0.04em;
		text-align: center;
		color: $color--red;

		& + & { border-top: 1px solid $color--blue; }
	}

	&__position--left &__players-list-item { border-right: 1px solid $color--blue; }
	&__position--right &__players-list-item { border-left: 1px solid $color--blue; }

	// Slick

	.slick-arrow {
		display: inline-block;
		width: 30px;
		height: 100%;
		overflow: hidden;
		margin: 0;
		padding: 0;
		background-color: inherit;
		position: absolute;

		&.slick-prev { left: -30px; }
		&.slick-next { right: -30px; }
	}
}
