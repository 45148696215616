// ----------------------------------------
// Aside layout

.al {
	margin-top: 40px;

	&--center {
		margin-top: 28px;

		@include media('>xl') { margin-top: 40px; }
	}

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 40px;

		@include media('>=xl') { flex-direction: row; }
	}

	&--center &__container {
		display: block;

		@include media('>=xl') { display: flex; }
	}

	&__content {
		flex: 0 1 100%;

		@include media('>=xl') { flex: 0 1 779px; }
	}

	&--center &__content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center; }

	&__aside {
		flex: 0 1 100%;
		margin-top: 20px;

		@include media('>=xl') { flex: 0 1 377px; }
	}
}
