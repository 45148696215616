// ----------------------------------------
// User navigation in navbar

.cl-user {
	position: relative;

	&:hover {
		cursor: pointer;

		&::before {
			display: inline-block;
			width: calc(100% + 1px);
			height: 100%;
			content: '';
			background-color: $color--red;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1; }
	}

	&__name {
		display: flex;
		align-items: center;
		height: 53px;
		padding: 0 27px;
		font: 400 14px / 1 $font--text;
		letter-spacing: 0.07em;
		color: $color--black;
		position: relative;
		z-index: 2;

		svg {
			display: inline-block;
			width: 12px;
			height: 8px;
			margin-left: 10px; }

		svg * { fill: $color--black; }

		&:focus { outline: none; }
	}

	&:hover &__name {
		color: $color--white;
		background-color: $color--red;

		svg {
			transform: rotate(180deg);

			* { fill: $color--white; }
		}
	}

	&__panel {
		display: none;
		width: calc(100% + 72px);
		height: 150px;
		background-color: $color--red;
		position: absolute;
		bottom: -150px;
		left: 0;
		z-index: 0;

		&::before {
			display: inline-block;
			width: 100%;
			height: 100px;
			content: '';
			background-color: $color--red;
			transform: skewY(-3.5deg);
			position: absolute;
			bottom: -12px;
			left: 0;
			z-index: -1; }
	}

	&:hover &__panel { display: block; }

	&__item {

		& + &::before {
			display: block;
			width: calc(100% - 56px);
			height: 1px;
			content: '';
			margin: 0 28px;
			background-color: #fc835e; }
	}

	&__link {
		display: block;
		padding: 12px 28px;
		font: 400 14px / 1 $font--text;
		letter-spacing: 0.1em;
		color: $color--white;

		&:hover { background-color: #d63e0f; }
	}
}
