//
// ----------------------------------------
// TYPOGRAPHY
//
// Beware using global styles!
// http://cssspecificity.com/
//

a {
	color: inherit;
	text-decoration: none; }
