// ----------------------------------------
// SM team search

.sm-team-search {
	display: block;

	@include media('>=md') { display: none; }

	&__trigger {
		display: block;
		padding: 20px 16px;
		background-color: $color--navy;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--red;
		position: relative;

		&::after {
			display: inline-block;
			width: 14px;
			height: 8px;
			content: '';
			background-image: icon('angle-red.svg');
			background-size: 100% 100%;
			position: absolute;
			top: calc(50% - 2px);
			right: 15px;
			tranform: translateY(-50%); }
	}

	&__modal {
		display: none;
		width: 100%;
		height: 100%;
		padding: 30px 18px;
		background-color: $color--black;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 21;

		&--active { display: block; }
	}

	&__modal-head {
		display: flex;
		justify-content: space-between;
		align-items: center; }

	&__modal-title {
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--white; }

	&__modal-close {
		display: inline-block;
		width: 16px;
		height: 16px;
		overflow: hidden;
		cursor: pointer;

		&::before {
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background-image: icon('close.svg');
			background-size: 100% 100%; }
	}

	&__modal-content { margin-top: 38px; }

	&__modal-select-wrapper {
		display: block;
		width: 100%;
		overflow: hidden;
		margin: 28px 0;
		border: 1px solid $color--red;
		position: relative;

		&::after {
			display: inline-block;
			width: 14px;
			height: 8px;
			content: '';
			background-image: icon('angle-red.svg');
			background-size: 100% 100%;
			position: absolute;
			top: calc(50% - 1px);
			right: 15px;
			transform: translateY(-50%);
			pointer-events: none; }
	}

	&__select {
		width: 130%;
		padding: 11px 9px 12px 28px;
		border: none;
		box-shadow: none;
		background-color: transparent;
		background-image: none;
		appearance: none;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--red;
		cursor: pointer;

		&:focus { outline: none; }
	}

	&__modal-submit {
		display: block;
		width: 100%;
		padding: 11px 9px 12px 9px;
		border: 1px solid $color--red;
		background-color: $color--black;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--red;
		cursor: pointer; }
}
