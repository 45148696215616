// ----------------------------------------
// Aside notice

.aside-notice {
	width: 100%;
	min-height: 189px;
	margin: 42px 0;
	padding: 22px 27px;
	background-color: $color--navy;
	background-image: icon('star-part-1.svg');
	background-size: cover;
	color: $color--brightest;
	position: relative;

	&__time {
		margin: 3px 0;
		font: 300 12px / 1 $font--text;
		letter-spacing: 0.05em; }

	&__title {
		margin: 9px 0;
		font: 900 35px / 40px $font--text; }

	&__author {
		margin: 3px 0;
		font: 300 12px / 16px $font--text;
		letter-spacing: 0.05em; }
}
