// ----------------------------------------
// Main nav

.sm-mainnav {
	width: 51px;
	height: 53px;

	&__trigger-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%; }

	&--active &__trigger-wrapper { background-color: $color--red; }

	&__trigger-icon {
		display: inline-block;
		width: 16px;
		height: 1px;
		background-color: $color--black;
		position: relative;
		top: 1px;

		&::before,
		&::after {
			display: inline-block;
			width: 16px;
			height: 1px;
			content: '';
			background-color: $color--black;
			position: absolute;
			left: 0; }

		&::before { top: -4px; }
		&::after { top: 4px; }
	}

	&--active &__trigger-icon { width: 26px; }

	&:hover &__trigger-icon,
	&:hover &__trigger-icon::before,
	&:hover &__trigger-icon::after { background-color: $color--red; }

	&--active &__trigger-icon {
		background-color: $color--red;

		&::before,
		&::after {
			width: 22px;
			background-color: $color--white;
			top: 1px; }

		&::before { transform: rotate(-45deg); }
		&::after { transform: rotate(45deg); }
	}

	&--active:hover &__trigger-icon::before,
	&--active:hover &__trigger-icon::after {
		background-color: $color--white;
	}

	&__content {
		display: none;
		width: 100%;
		min-height: calc(100vh - 53px);
		padding: 10px 15px;
		background-color: $color--red;
		position: absolute;
		top: 53px;
		left: 0; }

	&--active &__content { display: block; }

	&__item {
		border-bottom: 1px solid #fc835e;

		&--not-signed {
			display: flex;
			justify-content: space-between; }
	}

	&__item-name {
		display: block;
		padding: 12px 2px;
		font: 300 16px / 1 $font--text;
		color: $color--white;
		@include transition(color);
		position: relative;

		&:hover:not(&--sublist) { color: $color--black; }

		&--sublist::after {
			display: inline-block;
			width: 7px;
			height: 12px;
			content: '';
			background-image: icon('angle-white.svg');
			background-size: 100% 100%;
			position: absolute;
			top: calc(50% + 1px);
			right: 2px;
			transform: rotate(0) translate(0, -50%);
			@include transition(transform); }
	}

	&__item--active &__item-name--sublist::after { transform: rotate(90deg) translate(-50%, 0); }

	&__sublist {
		display: none;
		padding: 2px 0 18px 0; }

	&__subitem-name {
		display: block;
		padding: 8px 30px;
		font: 300 16px / 1 $font--text;
		color: $color--white;
		@include transition(color);

		&:hover { color: $color--black; }
	}

	&__social-list {
		display: flex;
		justify-content: space-between;
		padding: 12px; }

	&__social-item {
		display: flex;
		justify-content: center;
		align-items: center; }

	&__social-link {
		svg * {
			fill: $color--white;
			@include transition(fill); }

		&:hover svg * { fill: $color--black; }
	}
}
