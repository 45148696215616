// ----------------------------------------
// Hero

.hero {
	width: 100%;
	background-image: linear-gradient(#000, $color--navy);

	@include media('>=lg') {
		background-image: pic('hockey-blue.jpg');
		background-size: cover;
		background-position: center center; }

	&--calgary-flames {
		@include media('>=lg') { background-image: pic('calgary-flames.jpg'); }
	}

	&__container {
		padding-bottom: 6px;
		position: relative;

		@include media('>=md') { padding-bottom: 26px; }

		@include media('>=lg') { padding-bottom: 6px; }

		p {
			padding: 0 16%;
			font: 400 16px / 20px $font--text;
			letter-spacing: 0.03em;
			text-align: center;
			color: $color--brightest;

			@include media('>=lg') { padding: 0 32%; }
		}
	}

	&__add-wrapper {
		display: none;
		padding: 37px 0 25px 0;
		text-align: center;

		@include media('>=md') { display: block; }
	}

	&__add { max-width: 100%; }

	&__content {
		padding-top: 20px;

		&--verbose { padding-top: 0; }
	}

	&__title {
		padding: 4px 0;
		font: 400 40px / 1 $font--head;
		text-transform: uppercase;
		text-align: center;
		color: $color--red;
		word-wrap: break-word;

		@include media('>=md') {
			margin: 12px;
			padding: 0;
			font: 400 80px / 1 $font--head; }
	}

	&__nav-list {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 27px 15px;

		@include media('>=md') {
			position: absolute;
			left: 0;
			bottom: 0; }
	}

	&__nav-item {

	}

	&__nav-link {
		display: block;
		width: 65px;
		height: 33px;
		overflow: hidden;

		border: 1px solid $color--red;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--red;
		position: relative;

		@include media('>=md') {
			width: auto;
			height: auto;
			padding: 10px;
		}

		&::before {
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background-image: icon('arrow.svg');
			background-size: 22px 7px;
			background-repeat: no-repeat;
			background-position: center center;

			@include media('>=md') {
				width: 22px;
				height: 7px;
				background-size: 100% 100%;
				position: absolute;
				top: calc(50% + 1px);
				transform: translateY(-50%); }
		}

		&--prev {
			@include media('>=md') { padding: 10px 17px 9px 55px; }

			&::before {
				@include media('>=md') { left: 15px; }
			}
		}

		&--next {
			@include media('>=md') { padding: 10px 55px 9px 17px; }

			&::before {
				transform: rotate(180deg);

				@include media('>=md') {
					right: 15px;
					transform: translateY(-50%) rotate(180deg); }
			}
		}
	}

	&__subtitle {
		padding: 20px 0 0 0;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		text-align: center;
		color: $color--brightest;

		@include media('>=md') { padding: 0; }
	}

	&__title-less-important {
		display: none;

		@include media('>=md') { display: inline; }
	}

	&__social-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media('>=lg') {
			display: block;
			position: absolute;
			top: 18px;
			left: 30px; }
	}

	&__social-title {
		margin: 14px 0 7px 0;
		font: 400 12px / 1 $font--text;
		letter-spacing: 0.05em;
		color: $color--brightest;

		@include media('>=lg') { margin: 14px 0; }
	}

	&__social-list {
		display: flex;

		@include media('>=lg') { display: block; }
	}

	&__social-item {
		margin: 0 3px;

		@include media('>=lg') { margin: 0 0 3px 0; }
	}

	&__social-link {
		display: block;
		width: 30px;
		height: 30px;
		overflow: hidden;
		position: relative;

		@include media('>=lg') { background-color: $color--red; }

		svg {
			display: block;
			position: absolute;

			> * {
				fill: $color--red;

				@include media('>=lg') { fill: $color--white; }
			}
		}

		&--twitter svg {
			width: 16px;
			height: 15px;
			top: 8px;
			left: 8px; }

		&--facebook svg {
			width: 8px;
			height: 16px;
			top: 7px;
			left: 11px; }

		&--email svg {
			width: 18px;
			height: 12px;
			top: 9px;
			left: 6px; }
	}

	&__mobile-advertisement {
		margin: 23px 0 8px 0;
		text-align: center;

		@include media('>=md') { display: none; }
	}

	&__mobile-advertisement-image { max-width: 100%; }
}
