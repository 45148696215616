// ----------------------------------------
// Overlap

.overlap {
	display: inline-block;
	padding: 10px 22px;
	background-color: $color--blue;
	font: 400 12px / 1 $font--text;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	text-align: center;
	color: $color--white;
	white-space: nowrap;
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 11;
	transform: translate(-50%, -50%);

	&--red { background-color: $color--red; }
}
