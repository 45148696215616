// ----------------------------------------
// Form

.form {

	&__checkbox-group { margin: 20px 0; }

	&__checkbox-label {
		display: flex;
		align-items: center;
		height: 25px;
		padding-left: 38px;
		font: 400 16px / 1 $font--text;
		color: $color--dark;
		position: relative;
		cursor: pointer;

		&::before,
		&::after {
			content: '';
			position: absolute; }

		&::before {
			display: inline-block;
			width: 25px;
			height: 25px;
			background-color: $color--white;
			top: 0;
			left: 0;
			z-index: 3; }

		&::after {
			display: none;
			width: 11px;
			height: 8px;
			background-image: icon('checkmark.svg');
			background-size: 100% 100%;
			top: 9px;
			left: 7px;
			z-index: 4; }
	}

	&__checkbox { display: none; }

	&__checkbox:checked + &__checkbox-label {
		color: $color--red;

		&::after { display: inline-block; }
	}

	&__input-group { margin: 20px 0 30px 0; }

	&__input-label { display: none; }

	&__input {
		min-width: 267px;
		padding: 7px 12px 8px 12px;
		border: 1px solid $color--bright;
		border-radius: 0;
		background-color: $color--white;
		font: 400 16px / 21px $font--text;
		letter-spacing: 0.03em;
		color: $color--dark;
		-webkit-appearance: none;

		&:focus {
			outline: none;
			border-color: $color--red; }

		&::placeholder {
			font: 400 16px / 21px $font--text;
			letter-spacing: 0.03em;
			color: $color--bright; }

		&::-moz-placeholder { /* Firefox 19+ */
			font: 400 16px / 22px $font--text;
			letter-spacing: 0.03em;
			color: $color--bright;
		}
	}
}
