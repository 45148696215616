// ----------------------------------------
// News and Forum

.news {
	background-color: $color--navy;

	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 20px;

		@include media('>=xl') {
			flex-direction: row;
			align-items: flex-start;
			padding-bottom: 0; }
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: 806px;
		padding: 65px 0 15px 0;
		position: relative;

		@include media('>=xl') {
			flex: 1 0 806px;

			&::after {
				display: inline-block;
				width: 1px;
				height: 120px;
				content: '';
				background-color: $color--blue;
				position: absolute;
				top: 21%;
				right: 0; }
		}
	}

	&__title {
		padding-right: 27px;
		font: 400 80px / 1 $font--head;
		text-transform: uppercase;
		color: $color--blue;
		position: absolute;
		top: 12px;
		left: 50%;
		transform: translateX(-50%); }

	&__list {
		display: flex;
		flex-direction: column;
		width: 100%;
		position: relative;
		z-index: 3;

		@include media('>=md') { flex-direction: row; }
	}

	&__item {
		flex: 0 0 100%;
		font: 400 16px / 22px $font--text;
		color: #e8e8e8;

		@include media('>=md') {
			flex: 0 0 25%;
			padding-right: 50px; }

		p { margin: 5px 0; }
	}

	&__item-name {
		margin: 5px 0 0 0;
		font: 900 16px / 1 $font--text;
		color: $color--red; }

	&__item-time {
		display: block;
		margin: 8px 0;
		font: 300 12px / 1 $font--text;
		color: #939393; }

	&__content-footer { margin: 20px 0 13px 0; }

	&__forum {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 69px 0 15px 0;
		position: relative;

		@include media('>=md') { padding: 69px 30px 15px 30px; }

		@include media('>=xl') { flex: 0 1 377px; }
	}

	&__forum-list {
		width: 100%;
		position: relative;
		z-index: 3;

		@include media('>=md') { width: auto; }
	}

	&__forum-category {
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.12em;
		color: #e8e8e8; }

	&__forum-item-content {
		display: flex;
		justify-content: space-between;

		@include media('>=md') { justify-content: flex-start; }
	}

	&__forum-title {
		margin: 10px 0;
		padding-right: 30px;
		font: 700 21px / 24px $font--text;
		letter-spacing: 0.04em;
		word-wrap: break-word;
		color: $color--red; }

	&__forum-replies {
		margin-top: 13px;
		font: 300 12px / 1 $font--text;
		color: #939393;

		strong {
			display: block;
			margin-top: 10px;
			font: 400 21px / 1 $font--text;
			color: $color--brightest; }
	}

	&__forum-author {
		display: flex;
		font: 300 12px / 1 $font--text;
		letter-spacing: 0.05em;
		color: #939393; }

	&__forum-author-name {
		padding-right: 13px;
		border-right: 1px solid $color--blue; }

	&__forum-author-date { padding-left: 13px; }

	&__forum-footer { margin-top: 41px; }
}
