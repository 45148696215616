// ----------------------------------------
// Navigation content (on click)

.cl-navcontent {

	&::after {
		display: block;
		width: 100%;
		height: 150%;
		content: '';
		background-color: $color--red;
		position: absolute;
		top: -40%;
		left: 0;
		transform: skewY(-3.7deg);
		z-index: -1; }

	&__primary {
		display: flex;
		justify-content: space-between;
		padding: 17px 0;
		border-bottom: 1px solid #fc835e; }

	&__primary-list { display: flex; }

	&__primary-item {
		display: flex;
		align-items: center;
		margin-right: 24px; }

	&__primary-link {
		font: 400 14px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.07em;
		color: $color--white; }

	&__primary-draftkit {
		display: inline-block;
		padding: 8px 19px;
		border: 1px solid $color--white;
		background-color: $color--red;
		@include transition;
		font: 700 14px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		color: $color--white;

		&:hover {
			border-color: $color--black;
			background-color: $color--black; }
	}

	&__secondary {
		display: flex;
		justify-content: space-between;
		padding: 25px 0 5px 0;
		border-bottom: 1px solid #fc835e; }

	&__secondary-group { flex: 0 1 33%; }

	&__secondary-title {
		margin: 0;
		font: 300 20px / 1 $font--text;
		color: #353535; }

	&__secondary-item { margin: 25px 0; }

	&__secondary-link {
		font: 300 20px / 1 $font--text;
		color: $color--white;
		@include transition(color);

		&:hover { color: $color--dark; }
	}

	&__social { padding-top: 11px; }

	&__social-list { display: flex; }

	&__social-item {
		display: flex;
		align-items: center;
		margin-right: 12px; }

	&__social-link {

		svg * {
			fill: $color--white;
			@include transition(fill); }
	}

	&__social-link:hover svg * { fill: $color--black; }
}
