//
// ----------------------------------------
// COLORS
//
// Colors in variables.
// We have basically three colors in the project:
// - red
// - blue
// - navy
//
// We have also four grey tones:
// - brightest grey
// - bright grey
// - dark grey
// - darkest grey
//
// Two function colors:
// - correct
// - wrong
//
// And two regular:
// - black
// - white
//

$color--red: #f15a23;
$color--blue: #1a2784;
$color--navy: #151e4c;

$color--brightest: #e8e8e8;
$color--bright: #939393;
$color--dark: #353535;
$color--darkest: #000000;

$color--correct: #79c600;
$color--wrong: #e51534;

$color--black: #000000;
$color--white: #ffffff;
