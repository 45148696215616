// ----------------------------------------
// Desktop version of team search bar

.lg-team-search {
	display: none;
	background-color: $color--navy;

	@include media('>=md') { display: block; }

	&__container {
		display: flex;
		justify-content: space-between; }

	&__list {
		display: flex;
		width: 70%; }

	&__item {
		flex: 1 0 auto;
		border-right: 1px solid $color--black;
		letter-spacing: 0.05em; }

	&__trigger {
		padding: 25px 22px;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		color: $color--red;
		position: relative;
		cursor: pointer;

		&::after {
			display: inline-block;
			width: 14px;
			height: 8px;
			content: '';
			background-image: icon('angle-red.svg');
			background-size: 100% 100%;
			position: absolute;
			top: calc(50% + 1px);
			right: 30px;
			transform: translateY(-50%); }
	}

	&__submit-wrapper {
		display: flex;
		align-items: center; }

	&__btn {
		background-color: $color--navy;
		cursor: pointer; }
}
