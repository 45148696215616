// ----------------------------------------
// Comparison

.comparison {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 30px;

	position: relative;

	@include media('>=md') {
		width: auto;
		margin-bottom: 60px; }

	@include media('>=xl') { margin-bottom: 0; }

	&--start {
		margin-bottom: 8px;

		@include media('>=md') { margin: 20px 0 28px 0; }
		@include media('>=xl') { margin: 20px 0 28px 0; }
	}

	&__content {
		display: flex;
		max-width: 779px;
		padding: 26px 10px 5px 10px;
		background-color: $color--white;

		@include media('>=md') { padding: 32px 28px 0 28px; }
	}

	&--start &__content {
		padding: 0 10px;

		@include media('>=md') { padding: 32px 28px 0 28px; }
	}

	&__person {
		display: flex;
		flex-direction: column;
		flex: 0	1 256px; }

	&--start &__person {
		padding: 15px 0;

		@include media('>=md') { padding: 0; }
	}

	&__person-header {
		display: flex;
		align-items: center; }

	&__person--inverse &__person-header { flex-direction: row-reverse; }

	&__avatar-wrapper {
		display: none;
		width: 75px;
		height: 75px;
		overflow: hidden;
		background-color: #e8e8e8;
		position: relative;
		z-index: 5;

		@include media('>=md') { display: inline-block; }

		&::before {
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background-image: icon('avatar--grey.svg');
			background-size: 100% 100%; }

		&--confirmed::before { background-image: icon('avatar--green.svg'); }
	}

	&__avatar {
		display: block;
		height: 100%;
		position: absolute;
		top: -2px;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1; }

	&__person-wrapper-info {
		@include media('>=md') { margin-left: 14px; }
	}

	&__person--inverse &__person-wrapper-info {
		text-align: right;

		@include media('>=md') {
			margin-right: 14px;
			margin-left: 0; }
	}

	&__person-sosm {
		display: none;
		font: 400 12px / 1 $font--text;
		color: $color--red;

		@include media('>=md') { display: block; }
	}

	&__person-value {
		margin: 3px 0;
		font: 400 12px / 1 $font--text;
		letter-spacing: 0.05em;

		@include media('>=md') { font: 400 21px / 1 $font--text; }

		&--probable,
		&--confirmed {
			display: flex;
			flex-direction: row;
			align-items: center;

			&::before {
				display: inline-block;
				width: 19px;
				height: 19px;
				content: '';
				margin-right: 10px;
				background-size: 100% 100%;

				@include media('>=md') { display: none; }
			}
		}

		&--probable {
			color: $color--wrong;

			&::before { background-image: icon('probable.svg'); }

			@include media('>=md') { color: $color--black; }
		}

		&--confirmed {
			color: $color--correct;

			&::before { background-image: icon('confirmed.svg'); }
		}
	}

	&__person--inverse &__person-value {

		&--probable,
		&--confirmed {
			flex-direction: row-reverse;

			&::before {
				margin-right: 0;
				margin-left: 10px; }
		}
	}

	&__person-name-wrapper {
		margin: 11px 0 0 0;

		@include media('>=md') { margin: 27px 0 17px 0; }
	}

	&__person-team-wrapper {
		display: flex;
		align-items: center; }

	&__person--inverse &__person-team-wrapper { flex-direction: row-reverse; }

	&__person-team {
		font: 400 10px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.13em;

		@include media('>=md') { font: 400 12px / 1 $font--text; }
	}

	&__person-full-name {
		max-width: 150px;
		margin: 14px 0 0 0;
		font: 800 21px / 24px $font--text;
		color: $color--dark;

		@include media('>=md') {
			margin: 7px 0;
			font: 800 35px / 38px $font--text; }
	}

	&__person--inverse &__person-full-name {
		float: right;
		text-align: right; }

	&__person-first-name {
		display: inline-block;
		font-size: 0;

		@include media('>=md') { font: 800 35px / 38px $font--text; }

		&::first-letter {
			font: 800 21px / 24px $font--text;

			@include media('>=md') { font: 800 35px / 38px $font--text; }
		}

		&::after {
			font: 800 21px / 24px $font--text;
			content: '.';

			@include media('>=md') { content: ''; }
		}
	}

	&__person-footer-back {
		font: 400 10px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.05em;

		@include media('>=md') { font: 400 12px / 1 $font--text; }

		a {
			font-weight: 700;
			color: $color--red; }
	}

	&__person--inverse &__person-footer-back { text-align: right; }

	&__person-footer-list {
		display: none;
		margin: 20px 0;
		border: 1px solid #e8e8e8;

		@include media('>=md') { display: flex; }
	}

	&__person-footer-item {
		flex: 1 1 auto;
		min-width: 55px;
		padding: 10px 12px;
		font: 400 12px / 1 $font--text;
		letter-spacing: 0.2em;
		text-align: center;
		white-space: nowrap;
		color: $color--dark;

		& + & { border-left: 1px solid #e8e8e8; }
	}

	&__start-list {
		display: none;
		overflow: hidden;
		margin-bottom: 29px;
		background-color: $color--dark;
		position: relative;

		@include media('>=md') {
			display: flex;
			justify-content: space-between; }

		&::before {
			display: inline-block;
			width: 150px;
			height: 100%;
			content: '';
			background-color: $color--red;
			transform: skewX(30deg);
			position: absolute;
			top: 0;
			left: -70px; }
	}

	&__start-item {
		padding: 10px 15px;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		color: $color--white;
		position: relative;
		z-index: 3; }

	&__versus {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		flex: 0 1 211px;
		background-image: icon('versus.svg');
		background-repeat: no-repeat;
		background-size: auto 50%;
		background-position: center center;
		text-align: center;

		@include media('>=md') {
			padding: 60px 0 92px 0;
			background-position: center 43px;
			background-size: contain; }
	}

	&--start &__versus {
	    padding: 8% 0 20% 0;
		background-image: icon('versus-line.svg');
		background-size: contain;
		background-position: center bottom; }

	&__versus-place {
		display: none;
		font: 400 21px / 28px $font--text;

		@include media('>=md') { display: block; }
	}

	&__versus-analyse {
		display: none;
		border-bottom: 1px solid $color--red;
		font: 400 21px / 24px $font--text;
		color: $color--red;

		@include media('>=md') { display: block; }
	}

	&__versus-updated {
		display: none;
		font: 300 12px / 1 $font--text;
		letter-spacing: 0.05em;

		@include media('>=md') { display: block; }
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin: 9px 0 14px 0;

		@include media('>=md') {
			align-items: center;
			margin: 9px 0 28px 0; }
	}

	&__footer-first {
		flex: 1 1 256px;
		padding: 0 10px;
		text-align: left;
		min-width: 32px;

		@include media('>=md') { padding: 0 28px; }
	}

	&__footer-mobile-share {

		@include media('>=md') { display: none; }

		svg {
			display: block;
			width: 32px;
			height: 32px; }
	}

	&__footer-second {
		flex: 1 1 211px;
		text-align: center; }

	&__footer-updated {
		margin-bottom: 12px;
		font: 400 10px / 1 $font--text;

		@include media('>=md') { display: none; }
	}

	&__footer-analyse {
		display: inline-block;

		@include media('>=md') { display: none; }
	}

	&__footer-goalies {
		display: none;

		@include media('>=md') { display: inline-block; }
	}

	&__footer-third {
		flex: 1 1 256px;
		padding: 0 10px;
		text-align: right;
		min-width: 32px;

		@include media('>=md') { padding: 0 28px; }
	}

	&__footer-desktop-share {
		display: none;

		@include media('>=md') { display: flex; }
	}
}
