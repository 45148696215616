// ----------------------------------------
// Topbar in navbar

.xl-topbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 53px;
	background-color: $color--brightest;
	position: relative;

	&__header {
		display: flex;
		align-items: center;
		padding: 0 30px; }

	&__sponsored {
		padding-right: 20px;
		margin-right: 20px;
		font: 400 11px / 1 $font--text;
		letter-spacing: 0.05em;
		color: #636363;

		img {
			max-height: 30px;
			margin: 0 5px; }
	}

	&__title {
		font: 400 16px / 1 $font--text;
		letter-spacing: 0.065em;
		color: #636363; }

	&__main {
		display: flex;
		height: 100%; }

	&__list-item {	margin: 0 15px; }

	&__list-link {
		font: 400 14px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.07em;
		color: $color--black;
		@include transition(color);

		&:hover { color: $color--red; }
	}

	&__elem {
		display: flex;
		align-items: center;
		position: relative;

		&::after {
			display: inline-block;
			width: 1px;
			height: 25px;
			content: '';
			background-color: $color--white;
			position: absolute;
			top: 50%;
			right: -1px;
			transform: translateY(-50%); }

		&--static {
			position: static;

			&::after { display: none; }
		}
	}

	&__list { padding: 0 10px; }
}
