// ----------------------------------------
// Share button

.share-btn {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font: 400 12px / 1 $font--text;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: $color--red;

	&::after {
		display: inline-block;
		width: 31px;
		height: 31px;
		overflow: hidden;
		margin-left: 6px;
		content: '';
		background-image: icon('share--red.svg');
		background-size: 100% 100%; }
}
