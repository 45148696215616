// ----------------------------------------
// Search line

.search-line {

	&__container {
		margin: 27px auto 27px auto;
		position: relative;

		@include media('>=md') { margin: 36px auto 51px auto; }
	}

	&__label {
		display: flex;
		justify-content: center;
		align-items: center;
		width: calc(100% - 30px);
		height: 100%;
		background-color: $color--white;
		font: 400 12px / 1 $font--text;
		color: $color--bright;
		position: absolute;
		top: 0;
		left: 15px;
		cursor: pointer;

		@include media('>=sm') { font-size: 16px; }

		svg {
			display: inline-block;
			width: 12px;
			height: 12px;
			margin: 2px 0 0 8px;

			@include media('>=sm') {
				width: 18px;
				height: 18px;
				margin: 2px 0 0 10px; }
		}
	}

	&__input {
		font: 400 12px / 1 $font--text;
		color: $color--bright;
		width: 100%;
		padding: 20px 25px;
		border: none;

		@include media('>=sm') { font-size: 16px; }
	}

	&__input:focus ~ &__label { display: none; }

}
