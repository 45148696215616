// ----------------------------------------
// Forum

.forum {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 18px 16px 28px 16px;
	background-color: $color--navy;
	position: relative;

	@include media('>=sm') { padding: 24px 27px 25px 27px; }

	&__list {
		margin-top: 20px;
		letter-spacing: 0.05em;

		@include media('>=xl') { margin-top: 44px; }
	}

	&__item {
		display: flex;
		justify-content: space-between;
		padding: 17px 0 20px 0;
		border-top: 1px solid $color--blue;

		&:last-of-type { border-bottom: 1px solid $color--blue; }
	}

	&__item-body { padding-right: 7%; }

	&__title {
		margin-bottom: 14px;
		font: 700 17px / 24px $font--text;
		color: $color--red;

		@include media('>=sm') { font: 700 21px / 24px $font--text; }
	}

	&__item-footer { display: flex; }

	&__author {
		padding-right: 13px;
		border-right: 1px solid $color--bright;
		font: 300 10px / 1 $font--text;
		color: $color--bright;

		@include media('>=sm') { font: 300 12px / 1 $font--text; }
	}

	&__time {
		padding-left: 13px;
		font: 300 10px / 1 $font--text;
		color: $color--bright;

		@include media('>=sm') { font: 300 12px / 1 $font--text; }
	}

	&__item-aside {
		padding: 4px 5px 0 0;
		font: 300 10px / 1 $font--text;
		color: $color--bright;

		@include media('>=sm') { font: 300 12px / 1 $font--text; }

		strong {
			display: block;
			margin-top: 6px;
			font: 400 18px / 24px $font--text;
			color: $color--brightest;

			@include media('>=sm') { font: 400 21px / 28px $font--text; }
		}
	}

	&__footer {
		margin-top: 30px;
		text-align: center; }
}
