// ----------------------------------------
// Main navigation

.md-mainnav {
	width: 117px;
	height: 53px;

	&--active {
		position: static;

		&::after { display: none; }
	}

	&__trigger-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 117px;
		height: 53px;
		color: $color--black;
		cursor: pointer; }

	&:hover &__trigger-wrapper { color: $color--red; }

	&--active &__trigger-wrapper { background-color: $color--red; }

	&__trigger-icon {
		display: inline-block;
		width: 16px;
		height: 1px;
		margin-right: 12px;
		background-color: $color--black;
		position: relative;
		top: 1px;

		&::before,
		&::after {
			display: inline-block;
			width: 16px;
			height: 1px;
			content: '';
			background-color: $color--black;
			position: absolute;
			left: 0; }

		&::before { top: -4px; }
		&::after { top: 4px; }
	}

	&:hover &__trigger-icon,
	&:hover &__trigger-icon::before,
	&:hover &__trigger-icon::after { background-color: $color--red; }

	&--active &__trigger-icon {
		background-color: $color--red;

		&::before,
		&::after {
			width: 22px;
			background-color: $color--white;
			top: 1px; }

		&::before { transform: rotate(-45deg); }
		&::after { transform: rotate(45deg); }
	}

	&--active:hover &__trigger-icon::before,
	&--active:hover &__trigger-icon::after {
		background-color: $color--white;
	}

	&__trigger-name::before {
		font: 400 14px / 1 $font--text;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		content: 'Menu'; }

	&--active &__trigger-name::before {
		content: 'Close';
		color: $color--white; }

	&__content {
		display: none;
		width: 100%;
		padding: 0 18px;
		position: absolute;
		top: 100%;
		left: 0; }

	&--active &__content { display: block; }
}
