//
// ----------------------------------------
// GENERAL
//
// The most general styles for
// <html>, <body> and .container
//

body {
	background-color: $color--brightest;
	font-family: $font--text;
	color: $color--darkest; }

.container {
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;

	@include media('>=xl') { width: 1213px; }
}
