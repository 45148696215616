// ----------------------------------------
// Advertisement

.advertisement {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 400px;
	margin-top: 28px;
	background-color: $color--white;
	position: relative;

	@include media('>=xl') {
		height: auto;
		margin-top: 0; }

	&__name {
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.16em;
		color: $color--bright;
		position: absolute;
		top: 25px;
		left: 50%;
		transform: translateX(-50%); }

	&__image {
		max-width: 90%;

		@include media('>=lg') { max-width: unset; }
	}
}
