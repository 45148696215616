// ----------------------------------------
// Footer

.footer {
	background-color: $color--dark;

	&__logo {
		display: inline-block;
		width: 290px;
		height: 53px;
		overflow: hidden;
		margin: 29px 0 25px 0;

		@include media('>=sm') {
			width: 336px;
			height: 61px; }

		@include media('>=lg') { margin: 29px 0 51px 0; }

		&::before {
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background-image: brand('left-wing-lock-fantasy-hockey.svg');
			background-size: 100% 100%; }
	}

	&__content {
		display: flex;
		flex-direction: column;
		letter-spacing: 0.04em;

		@include media('>=lg') { flex-direction: row; }
	}

	&__title {
		margin: 25px 0 15px 0;
		font: 400 16px / 1 $font--text;
		color: $color--brightest;

		&--margin {
			@include media('>=lg') { margin-top: 38px; }
		}

		&:first-of-type { margin: 0; }
	}

	p {
		margin: 15px 0;
		font: 300 12px / 16px $font--text;
		color: $color--bright; }

	&__big-link {
		display: inline-block;
		margin: 10px 0;
		padding-bottom: 5px;
		border-bottom: 1px solid $color--red;
		font: 400 16px / 1 $font--text;
		color: $color--red; }

	// ----------------------------------------
	// About

	&__about {
		flex: 0 1 33%;
		padding: 0 25px 20px 0;

		@include media('>=lg') { padding: 0 25px 48px 0; }
	}

	// ----------------------------------------
	// Connect

	&__connect {
		flex: 0 1 33%;
		padding: 0 0 25px 0;

		@include media('>=lg') {
			padding: 0 28px 48px 28px;
			border-left: 1px solid $color--black; }
	}

	// ----------------------------------------
	// Contact

	&__contact {
		flex: 0 1 33%;
		padding: 0 0 25px 0;

		@include media('>=lg') {
			padding: 0 0 48px 28px;
			border-left: 1px solid $color--black;
		}
	}

	&__announcements-list {}

	&__announcements-item {
		margin: 6px 0 12px 0;
		font: 300 12px / 18px $font--text;
		color: $color--brightest;

		span {
			display: block;
			color: $color--bright; }
	}
}
