// ----------------------------------------
// Social icons

.social-icon {

	&--twitter {
		width: 25px;
		height: 25px; }

	&--google {
		width: 27px;
		height: 17px; }

	&--facebook {
		width: 16px;
		height: 25px; }

	&--youtube {
		width: 25px;
		height: 25px; }

	&--pinterest {
		width: 25px;
		height: 25px; }
}

