// ----------------------------------------
// Scoring

.scoring {
	width: 100%;
	background-color: $color--white;
	position: relative;

	&__content {
		padding: 18px 16px 28px 16px;

		@include media('>=sm') { padding: 24px 27px 28px 27px; }
	}

	&__time {
		display: block;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		text-align: center; }

	&__switcher {
		display: flex;
		width: 100%;
		margin: 12px 0 17px 0;
		border: 1px solid $color--red;

		@include media('>=sm') { margin: 20px 0 18px 0; }
	}

	&__switcher-item { flex: 1 0 50%; }

	&__switcher-link {
		display: block;
		width: 100%;
		padding: 9px;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		text-align: center;
		color: $color--red;

		&--active {
			background-color: $color--red;
			color: $color--white; }
	}

	&__table {
		width: 100%;
		border-collapse: collapse;

		th {
			padding: 4px 0;
			border-bottom: 1px solid $color--brightest;
			font: 400 10px / 1 $font--text;
			text-transform: uppercase;
			letter-spacing: 0.15em;
			text-align: center;

			@include media('>=sm') {
				padding: 6px 0;
				font: 400 12px / 1 $font--text; }

			&:first-of-type {
				text-align: left;

				@include media('>=xl') { width: 200px; }
			}
		}

		td {
			padding: 5px 0;
			border-bottom: 1px solid $color--brightest;
			letter-spacing: 0.03em;
			font: 400 12px / 1 $font--text;
			text-align: center;
			color: $color--red;
			position: relative;

			@include media('>=sm') { font: 400 16px / 1 $font--text; }

			&::before {
				display: inline-block;
				width: 1px;
				height: calc(100% - 8px);
				content: '';
				background-color: $color--brightest;
				position: absolute;
				top: 4px;
				left: 0; }

			&:first-of-type {
				border-left: 0;
				text-align: left;
				color: $color--dark;

				@include media('>=xl') { width: 200px; }

				&::before { display: none; }
			}
		}

		tbody tr:first-of-type td { padding: 9px 0 8px 0; }
	}

	&__table-player {
		font: 900 16px / 21px $font--text !important;

		@include media('>=sm') { font: 900 21px / 26px $font--text !important; }
	}

	&__more-wrapper {
		margin: 28px 0 0 0;
		text-align: center; }
}
