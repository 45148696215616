// ----------------------------------------
// Changes

.changes {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 15px 10px 5px 10px;
	background-color: $color--white;
	position: relative;

	@include media('>=md') {
		width: 377px;
		padding: 23px 27px; }

	&__item-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 12px 0;

		@include media('>=md') {
			align-items: flex-end;
			padding: 19px 0; }

		& + & { border-top: 1px solid #e8e8e8; }
	}

	&__team { display: flex; }

	&__team-name {
		font: 400 10px / 13px $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;

		@include media('>=md') { font: 400 12px / 1 $font--text; }
	}

	&__name {
		margin: 11px 0 9px 0;
		font: 900 21px / 1 $font--text;
		letter-spacing: 0.03em;
		color: $color--dark; }

	&__time {
		font: 300 12px / 1 $font--text;
		letter-spacing: 0.05em; }

	&__direction {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;
		font: 400 12px / 1 $font--text;
		letter-spacing: 0.03em;

		@include media('>=md') { font: 400 16px / 1 $font--text; }

		&::before {
			display: inline-block;
			width: 23px;
			height: 23px;
			content: '';
			margin-bottom: 8px;
			background-size: 100% 100%;

			@include media('>=md') {
				width: 30px;
				height: 30px; }
		}

		&--up {
			color: $color--correct;

			&::before { background-image: icon('up.svg'); }
		}

		&--down {
			color: $color--wrong;

			&::before { background-image: icon('down.svg'); }
		}
	}

	&__footer {
		margin: 13px 0;
		text-align: center;

		@include media('>=lg') { margin: 13px 0 0 0; }
	}
}
