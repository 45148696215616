// ----------------------------------------
// Aside list

.aside-list {
	width: 100%;
	margin: 42px 0;
	padding: 28px 27px;
	background-color: $color--navy;
	position: relative;

	&__item {
		margin: 28px 0 10px 0;
		letter-spacing: 0.035em; }

	&__item-author {
		display: block;
		font: 900 16px / 1 $font--text;
		color: $color--red; }

	&__item-title {
		display: block;
		margin: 6px 0;
		font: 400 16px / 21px $font--text;
		color: $color--brightest; }

	&__item-time {
		display: block;
		margin: 15px 0 10px 0;
		font: 300 12px / 1 $font--text;
		color: $color--bright; }

	&__footer {
		margin-top: 27px;
		text-align: center; }
}
