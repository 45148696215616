// ----------------------------------------
// Tiles

.tiles {

	// ----------------------------------------
	// Red frame

	&--frame {
		margin-top: 84px;
		border-top: 14px solid $color--red;
		border-bottom: 14px solid $color--red;
		position: relative;

		@include media('>=xl') {
			border-left: 14px solid $color--red;
			border-right: 14px solid $color--red; }

		&::before,
		&::after {
			display: inline-block;
			content: '';
			position: absolute;
			top: -7px;
			left: 50%;
			transform: translate(-50%, -50%); }

		&::before {
			width: 98px;
			height: 80px;
			background-color: $color--brightest;

			@include media('>=sm') {
				width: 174px;
				height: 100px; }
		}

		&::after {
			width: 52px;
			height: 46px;
			background-image: icon('hockey.svg');
			background-size: 100% 100%;

			@include media('>=sm') {
				width: 80px;
				height: 69px; }
		}
	}

	// ----------------------------------------

	&__container { position: relative; }

	&--frame &__container {
		padding: 0;

		@include media('>=xl') { padding: 0 15px; }
	}

	&__list {
		display: block;

		@include media('>=xl') {
			display: flex;
			justify-content: space-between;
			align-items: stretch; }
	}

	&--regular &__list {
		display: block;

		@include media('>=lg') {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: stretch; }

		@include media('>=xl') { flex-wrap: nowrap; }
	}

	&__item {
		display: flex;

		@include media('>=xl') { flex: 0 1 375px; }

		&:focus { outline: none; }
	}

	&--regular &__item {
		flex: 1 0 50%;

		@include media('>=xl') { flex: 0 1 375px; }
	}

	&--frame &__item {
		padding: 58px 14px 42px 14px;

		@include media('>=sm') { padding: 77px 14px 62px 14px; }
		@include media('>=xl') { padding: 77px 0 62px 0; }
	}

	&__regular-title {
		margin: 40px 0;
		font: 400 49px / 1 $font--head;
		text-transform: uppercase;
		text-align: center;
		color: $color--blue;

		@include media('>=lg') { font: 400 80px / 1 $font--head; }
		@include media('>=xl') { margin-bottom: 60px; }
	}

	&__regular-show-all {
		display: none;

		@include media('>=xl') {
			display: inline-block;
			padding: 9px 20px;
			border: 1px solid $color--red;
			font: 400 12px / 13px $font--text;
			text-transform: uppercase;
			letter-spacing: 0.15em;
			white-space: nowrap;
			color: $color--red;
			position: absolute;
			top: 15px;
			right: 15px; }
	}

	// ----------------------------------------
	// Tiles slick

	.slick-dots {
		display: flex;
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);

		@include media('>=sm') { bottom: 26px; }

		li {
			display: inline-block;
			width: 10px;
			height: 10px;
			margin-left: 13px;
			overflow: hidden;
			border-radius: 50%;

			&:first-of-type { margin: 0; }
		}

		button {
			border: none;
			background-color: $color--white;
			width: 100%;
			height: 100%;
			overflow: hidden;

			&::before {
				display: block;
				width: 100%;
				height: 100%;
				content: ''; }
		}

		.slick-active button { background-color: $color--blue; }
	}
}
