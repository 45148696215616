// ----------------------------------------
// Triple

.triple {
	width: 100%;
	margin-left: 0;

	@include media('>=lg') { margin-left: 28px; }
	@include media('>=xl') { margin-left: 0; }

	@include media('>=lg') {
		flex: 1 1 375px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-width: unset;
		min-width: 375px;
		width: auto;
		height: 622px; }

	&__tile {
		max-width: 100%;
		margin: 28px 7px 0 7px;
		padding: 13px;
		background-size: cover;
		background-repeat: no-repeat;

		@include media('>=lg') {
			flex: 1 0 auto;
			margin: 0;
			padding: 25px 28px;
			background-size: 100% 100%; }

		&:focus { outline: none; }

		&--star1 {
			background-color: $color--navy;
			background-image: icon('star-part-1.svg'); }

		&--star2 {
			background-color: $color--navy;
			background-image: icon('star-part-2.svg'); }

		&--star3 {
			background-color: $color--dark;
			background-image: icon('star-part-3.svg'); }

		& + & {
			@include media('>=lg') { margin-top: 28px; }
		}
	}

	&__time {
		font: 300 12px / 1 $font--text;
		letter-spacing: 0.05em;
		color: $color--bright;

		@include media('>=lg') { color: $color--brightest; }
	}

	&__title {
		margin: 10px 0;
		font: 900 21px / 25px $font--text;
		color: $color--brightest;

		@include media('>=lg') { font: 900 35px / 40px $font--text; }
	}

	&__author {
		margin: 12px 0;
		font: 300 12px / 1 $font--text;
		letter-spacing: 0.05em;
		color: $color--bright;

		@include media('>=lg') { color: $color--brightest; }
	}

	&__share {
		margin-top: 16px;
		font-size: 0;

		&::before {
			display: inline-block;
			width: 31px;
			height: 31px;
			overflow: hidden;
			margin-left: 6px;
			content: '';
			background-image: icon('share--white.svg');
			background-size: 100% 100%; }

		&:focus { outline: none; }

		@include media('<lg') {
			&::after { display: none; }
		}

		@include media('>=lg') {
			font: 400 12px / 1 $font--text;

			&::before {	display: none; }
		}
	}
}
