// ----------------------------------------
// Main nav

.xl-mainnav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 30px;
	background-color: $color--white;
	position: relative;

	&__content {
		display: flex;
		align-items: center; }

	&__logo-link { margin: 3px 39px 0 0; }

	&__logo {
		display: inline-block;
		width: 338px;
		height: 61px;
		overflow: hidden;

		&::before {
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background-image: brand('left-wing-lock.svg');
			background-size: 100% 100%; }
	}

	&__nav-list {
		height: 114px;
		display: flex; }

	&__nav-item {
		display: flex;
		height: 100%;

		& + &::before {
			display: inline-block;
			width: 1px;
			height: 25px;
			content: '';
			background-color: $color--brightest;
			position: relative;
			top: 50%;
			left: 0;
			transform: translateY(-50%); }
	}

	&__nav-link {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0 29px;
		font: 300 20px / 1 $font--text;
		letter-spacing: 0.02em;
		color: #353535;
		@include transition(red);
		position: relative; }

	&__nav-item:hover &__nav-link {

		color: $color--red;

		&::before {
			display: inline-block;
			width: 100%;
			height: 8px;
			content: '';
			background-color: $color--red;
			position: absolute;
			left: 0;
			bottom: 0; }
	}

	&__nav-item:hover &__subnav { display: block; }

	&__draftkit {
		display: inline-block;
		padding: 10px 18px;
		border: 1px solid $color--red;
		font: 700 14px / 15px $font--text;
		text-transform: uppercase;
		letter-spacing: 0.07em;
		color: $color--red;
		@include transition;

		&:hover {
			background-color: $color--red;
			color: $color--white; }
	}
}
