// ----------------------------------------
// Mobile select

.mobile-select {
	display: inline-block;
	width: 100%;
	max-width: 275px;
	overflow: hidden;
	margin: 10px 28px 10px 0;
	background: $color--white;
	font: 400 12px / 1 $font--text;
	text-transform: uppercase;
	text-align: left;
	position: relative;
	z-index: 10;
	user-select: none;

	&--small {
		max-width: 90px;
		width: auto;
		margin: 0; }

	&::after {
		display: inline-block;
		width: 14px;
		height: 8px;
		content: '';
		background-image: icon('angle-red.svg');
		background-size: 100% 100%;
		position: absolute;
		top: calc(50% - 1px);
		right: 15px;
		transform: translateY(-50%);
		pointer-events: none; }

	select {
		width: 130%;
		padding: 3px 9px 3px 22px;
		border: none;
		box-shadow: none;
		background-color: transparent;
		background-image: none;
		appearance: none;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--red;
		cursor: pointer;

		&:focus { outline: none; }
	}

	&--small select {
		padding: 12px 35px 12px 21px;
	}
}
