// ----------------------------------------
// Goalies

.goalies {
	letter-spacing: 0.03em;

	&__time {
		margin: 5px 0;
		font: 400 16px / 1 $font--text;
		color: $color--darkest; }

	&__content {
		display: flex;
		flex-direction: column;
		margin: 28px 0 19px 0;
		padding: 12px 0 16px 0;
		border-top: 1px solid $color--white;
		border-bottom: 1px solid $color--white;

		@include media('>=sm') { flex-direction: row; }
	}

	&__content-title {
		margin-bottom: 20px;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		color: $color--darkest;

		@include media('>=sm') { margin-bottom: 0; }
	}

	&__content-list {
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@include media('>=sm') { flex-direction: row; }
	}

	&__content-item {
		flex: 1 0 auto;
		display: flex;
		justify-content: center;
		align-items: center; }

	&__avatar-wrapper {
		display: inline-block;
		width: 76px;
		height: 76px;
		overflow: hidden;
		position: relative;

		&::before {
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background-image: icon('white-frame.svg');
			background-size: 100% 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3; }
	}

	&__avatar {
		display: block;
		max-height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2; }

	&__content-item-name {
		margin-left: 9px;
		font: 400 21px / 1 $font--text;
		color: $color--red; }
}
