// ----------------------------------------
// Social icons list

.xl-social {
	padding: 0 22px;

	&__item { padding: 0 6px; }

	&__link {
		display: flex;
		align-items: center;
		overflow: hidden;

		svg * {
			fill: #353535;
			@include transition(fill); }

		&:hover svg * { fill: $color--red; }
	}
}
