// ----------------------------------------
// Social icons

.social {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&--contact {
		margin: 23px 0;
		padding: 0 30px;

		@include media('>=md') { padding: 0 20%; }
	}

	&--footer {
		width: 70%;
		max-width: 200px;
		margin: 10px 0 25px 0;

		@include media('>=lg') {
			max-width: unset;
			margin: 10px 0 50px 0; }
	}

	&__link {
		display: block;

		svg {
			display: block;
			width: 100%;
			height: 100%; }

		svg * { fill: $color--white; }

		&--twitter {
			width: 30px;
			height: 30px; }

		&--google {
			width: 32px;
			height: 22px; }

		&--facebook {
			width: 20px;
			height: 38px; }

		&--youtube {
			width: 35px;
			height: 35px; }

		&--pinterest {
			width: 35px;
			height: 35px; }
	}

	&--footer &__link {

		svg * { fill: $color--brightest; }

		&--twitter {
			width: 25px;
			height: 25px; }

		&--google {
			width: 23px;
			height: 15px; }

		&--facebook {
			width: 16px;
			height: 30px; }

		&--youtube {
			width: 25px;
			height: 25px; }

		&--pinterest {
			width: 25px;
			height: 25px; }
	}
}
