// ----------------------------------------
// Team colors

.team-colors {
	margin: 0 10px 0 3px;
	font-size: 0;
	line-height: 0;
	white-space: nowrap;

	&--inverse { margin: 0 3px 0 10px; }

	&__item {
		display: inline-block;
		width: 8px;
		height: 12px;
		background-color: #eee;
		transform: skewX(25deg); }

	&--inverse &__item { transform: skewX(-25deg); }
}
