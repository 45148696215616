// ----------------------------------------
// Pick

.pick {
	width: 100%;
	background-color: $color--blue;
	position: relative;

	&__content {
		width: 100%;
		height: 100%;
		overflow: hidden;
		padding: 0 28px;
		text-align: center;
		position: relative;
		z-index: 10;

		&::before {
			display: block;
			width: 100%;
			height: 300px;
			content: '';
			background-color: $color--navy;
			transform: skewY(22deg);
			position: absolute;
			top: -154px;
			left: 0;
			z-index: -1; }
	}

	&__share {
		font-size: 0;
		position: absolute;
		top: 25px;
		right: 28px;

		&::before {
			display: inline-block;
			width: 31px;
			height: 31px;
			overflow: hidden;
			margin-left: 6px;
			content: '';
			background-image: icon('share--white.svg');
			background-size: 100% 100%; }

		@include media('<lg') {
			&::after { display: none; }
		}

		@include media('>=lg') {
			font: 400 12px / 1 $font--text;

			&::before {	display: none; }
		}
	}

	&__avatar-wrapper {
		display: inline-block;
		width: 143px;
		height: 143px;
		margin-top: 42px;
		overflow: hidden;
		position: relative;

		&::after {
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background-image: icon('pick-wrapper.svg');
			background-size: 100% 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 11; }
	}

	&__avatar {
		width: 102px;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		transform: translate(-50%, -50%); }

	&__name {
		margin: 15px 0 10px 0;
		font: 900 24px / 1 $font--text;
		color: $color--brightest;

		@include media('>=sm') {
			margin: 20px 0 10px 0;
			font: 900 35px / 1 $font--text; }
	}

	&__team {
		margin: 10px 0 15px 0;
		font: 400 10px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--brightest;

		@include media('>=sm') { font: 400 12px / 1 $font--text; }
	}

	&__scoring-title {
		margin: 15px 0 0 0;
		font: 400 10px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--red;

		@include media('>=sm') { font: 400 12px / 1 $font--text; }
	}

	&__basis-scores-wrapper {
		display: flex;
		justify-content: space-between;
		margin: 25px 0 7px 0; }

	&__basic-score-wrapper {
		flex: 1 1 33%;
		font: 400 10px / 1 $font--text;
		color: $color--bright;

		@include media('>=sm') { font: 400 12px / 1 $font--text; }
	}

	&__basic-score {
		display: block;
		margin-top: 5px;
		font: 100 30px / 1 $font--text;
		color: $color--red;

		@include media('>=sm') { font: 100 63px / 1 $font--text; }
	}

	&__additional-scores-wrapper {
		display: flex;
		justify-content: space-between;
		margin: 20px 0 13px 0;

		@include media('>=sm') { margin: 7px 0 13px 0; }
	}

	&__additional-score-wrapper {
		flex: 1 1 25%;
		font: 400 10px / 1 $font--text;
		color: $color--bright;

		@include media('>=sm') { font: 400 12px / 1 $font--text; }
	}

	&__additional-score {
		display: block;
		margin-top: 10px;
		font: 300 22px / 1 $font--text;
		color: $color--red;

		@include media('>=sm') { font: 300 28px / 1 $font--text; }
	}

	&__footer {
		padding: 15px 15px 20px 15px;
		border-top: 1px solid $color--black;
		font: 400 10px / 14px $font--text;
		letter-spacing: 0.05em;
		color: $color--brightest;

		@include media('>=sm') {
			padding: 22px 20px 15px 20px;
			font: 400 12px / 16px $font--text; }
	}
}
