// ----------------------------------------
// Bottom

.bottom {
	background-color: $color--bright;

	&__container {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		padding-top: 10px;
		padding-bottom: 10px;
		letter-spacing: 0.04em;

		@include media('>=sm') { align-items: flex-start; }

		@include media('>=lg') {
			flex-direction: row;
			justify-content: space-between;
			align-items: center; }
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media('>=sm') {
			flex-direction: row;
			align-items: flex-start; }
	}

	&__item {
		padding: 6px 0;
		font: 300 12px / 1 $font--text;
		color: $color--dark;

		@include media('>=sm') { padding: 6px 18px 6px 0; }

		& + & {

			@include media('>=sm') {
				padding-left: 18px;
				border-left: 1px solid $color--brightest; }
		}
	}

	&__btn {
		margin: 15px 0;

		@include media('>=sm') { align-self: flex-end; }
		@include media('>=lg') { margin: 0; }
	}
}
