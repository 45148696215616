//
// ----------------------------------------
// BREAKPOINTS
//

$breakpoints: (sm: 576px, md: 768px, lg: 992px, xl: 1240px);

body::before {
	content: '#{$breakpoints}';
	display: none; }
