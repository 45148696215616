// ----------------------------------------
// Simple advertisement

.simple-advertisement {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 41px 0;
	background-color: $color--white;

	&__image {
		display: block;
		max-width: 90%; }

	&--mobile-hide {
		display: none;

		@include media('>=md') { display: flex; }
	}
}
