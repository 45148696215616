// ----------------------------------------
// Desktop navigation bar

.xl-navbar {
	display: none;

	@include media('>=xl') {
		display: block;
		position: relative;
		z-index: 20; }
}
