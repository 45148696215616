// ----------------------------------------
// CS skin

.custom-select {
	display: inline-block;
	width: 100%;
	max-width: 275px;
	margin: 10px 28px 10px 0;
	background: $color--white;
	font: 400 12px / 1 $font--text;
	text-transform: uppercase;
	text-align: left;
	position: relative;
	z-index: 10;
	user-select: none;

	&--small {
		width: auto;
		margin: 0; }

	&:focus { outline: none; }

	select { display: none; }

	.cs-placeholder {
		display: block;
		overflow: hidden;
		padding: 12px 65px 12px 21px;
		letter-spacing: 0.15em;
		color: $color--red;
		white-space: nowrap;
		text-overflow: ellipsis;
		position: relative;
		cursor: pointer;

		&::after {
			display: inline-block;
			width: 13px;
			height: 8px;
			content: '';
			background-image: icon('angle-red.svg');
			background-size: 100% 100%;
			speak: none;
			position: absolute;
			top: 50%;
			right: 29px;
			transform: translateY(-50%);
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale; }
	}

	&.cs-active {
		z-index: 20;

		.cs-placeholder::after { transform: translateY(-50%) rotate(180deg); }
	}

	.cs-options {
		width: 100%;
		overflow: hidden;
		background: $color--white;
		font: 400 12px / 1 $font--text;
		color: $color--dark;
		opacity: 0;
		transition: opacity 0.2s, visibility 0s 0.2s;
		visibility: hidden;
		position: absolute;

		ul {
			width: 100%;

			li {
				border-top: 1px solid $color--brightest;

				span {
					display: block;
					padding: 12px 65px 12px 21px;
					color: $color--black;
					@include transition(color);
					backface-visibility: hidden;
					cursor: pointer; }

				span:hover,
				&.cs-focus span {
					color: $color--red;
					// background: $color--brightest;
				}
			}
		}
	}

	&.cs-active .cs-options {
		visibility: visible;
		opacity: 1;
		@include transition(opacity); }

	.cs-selected span {
		color: $color--red !important;

		&::after {
			display: inline-block;
			width: 10px;
			height: 8px;
			content: '';
			margin-left: 10px;
			background-image: icon('checkmark.svg');
			background-size: 100% 100%;
			opacity: 1;
			@include transition(opacity);
			position: relative;
			top: -1px; }
	}
}
