// ----------------------------------------
// Services

.services {
	background-color: $color--black;
	font: 400 16px / 22px $font--text;
	color: $color--brightest;

	&__container {
		display: flex;
		flex-direction: column;
		min-height: 453px;
		padding: 0;
		background-color: $color--brightest;
		text-align: center;

		@include media('>=lg') { flex-direction: row; }
		@include media('>=xl') { background-color: $color--black; }
	}

	// ----------------------------------------
	// Common

	&__title {
		display: inline-block;
		padding: 10px 20px;
		background-color: $color--brightest;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--blue; }

	&__list {
		margin: 25px 0;
		font: 400 14px / 16px $font--text;
		letter-spacing: 0.05em;
		text-align: left;

		@include media('>=sm') { margin: 25px 0; }
		@include media('>=lg') { margin: 15px 0; }
	}

	&__list-item {
		margin: 6px 0;
		padding-left: 10px;
		position: relative;

		&::before {
			display: inline-block;
			width: 3px;
			height: 3px;
			content: '';
			border-radius: 50%;
			background-color: $color--brightest;
			position: absolute;
			top: 7px;
			left: 0; }
	}

	&__under-list {
		@include media('>=sm') { text-align: left; }
	}

	// ----------------------------------------
	// Email

	&__email {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
		padding: 57px 0 0 0;
		background-image: pic('hockey-players2.jpg');
		background-size: cover;

		@include media('>=lg') { flex: 0 0 50%; }
	}

	&__header { flex: 0 0 auto; }

	&__email-info {
		margin: 15px 0;
		padding: 0 15px;
		letter-spacing: 0.05em;

		@include media('>=sm') { margin: 25px 0; }
		@include media('>=lg') { padding: 0 20px; }
	}

	&__email-split {
		flex: 1 0 auto;
		width: 100%;
		padding: 0 15px 250px 15px;
		background-image: pic('phone-01.png');
		background-repeat: no-repeat;
		background-position: center calc(100% + 150px);
		background-size: auto 360px;

		@include media('>=sm') {
			padding: 0 15px 57px calc(50% - 15px);
			background-position: 12% top; }

		@include media('>=lg') {
			padding: 0 0 0 calc(50% - 15px);
			background-size: 32% auto; }
	}

	// ----------------------------------------
	// Podcast

	&__podcast {
		overflow: hidden;
		padding: 57px 0 57px 0;
		background-color: $color--navy;
		position: relative;
		z-index: 3;

		@include media('>=lg') { flex: 0 0 50%; }

		&::before {
			display: block;
			width: 100%;
			height: 500px;
			content: '';
			background-color: $color--blue;
			position: absolute;
			top: -330px;
			left: 0;
			z-index: -1;
			transform: skewY(-15deg); }

		@include media('>=lg') { padding-bottom: 0; }
	}

	&__podcast-info {
		margin-top: 15px;
		padding: 0 15px;
		letter-spacing: 0.05em;

		@include media('>=sm') { margin-top: 25px; }
		@include media('>=lg') { padding: 0 83px; }
	}

	&__podcast-content { margin-bottom: 30px; }

	&__podcast-title {
		display: block;
		margin: 22px 0 12px 0;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--bright; }

	&__podcast-image { max-width: 90%; }

	// ----------------------------------------
	// Contact

	&__contact {
		overflow: hidden;
		padding: 57px 0 25px 0;
		background-color: $color--navy;
		position: relative;
		z-index: 3;

		@include media('>=lg') { flex: 0 0 50%; }

		&::before {
			display: block;
			width: 100%;
			height: 500px;
			content: '';
			background-color: $color--blue;
			position: absolute;
			top: -330px;
			left: 0;
			z-index: -1;
			transform: skewY(11deg); }

		@include media('>=lg') { padding-bottom: 0; }
	}

	&__contact-content {
		margin: 41px 0;
		padding: 0 15px;

		@include media('>=lg') { padding: 0 50px; }
	}

	&__contact-subtitle {
		display: block;
		margin: 22px 0 8px 0;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--bright; }

	&__contact-info {
		margin: 0 0 23px 0;
		padding: 0;
		letter-spacing: 0.05em; }

	&__social { margin: 56px 0 20px 0; }

	// ----------------------------------------
	// App

	&__app {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
		padding: 57px 0 0 0;
		background-image: pic('hockey-players2.jpg');
		background-size: cover;

		@include media('>=lg') { flex: 0 0 50%; }
	}

	&__header { flex: 0 0 auto; }

	&__app-info {
		margin: 15px 0 0 0;
		padding: 0 15px;
		letter-spacing: 0.05em;

		@include media('>=sm') {
			margin: 25px 0 0 0;
			padding: 0 60px; }
	}

	&__app-split {
		flex: 1 0 auto;
		width: 100%;
		padding: 0 15px 280px 15px;
		background-image: pic('phone-02.png');
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: auto 250px;

		@include media('>=sm') {
			padding: 0 15px 35px calc(50% - 15px);
			background-position: 13% bottom;
			background-size: auto 95%; }

		@include media('>=lg') {
			background-size: 32% auto;
			background-position: 12% bottom; }
	}

	&__app-store-wrapper {
		margin: 16px 0;

		@include media('>=sm') { text-align: left; }
	}

	&__app-store-link {
		display: inline-block;
		width: 135px;
		height: 40px;

		img {
			display: block;
			width: 100%;
			height: 100%; }
	}
}
