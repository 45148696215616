// ----------------------------------------
// Simple form

.simple-form {
	display: flex;
	justify-content: center;

	&--services {
		max-width: 74%;
		margin: 0 auto; }

	&--footer {
		max-width: 400px;
		margin-top: 20px;

		@include media('>=lg') { max-width: unset; }
	}

	&__label { display: none; }

	&__input {
		width: calc(100% - 100px);
		padding: 4px 12px;
		border: 1px solid $color--bright;
		border-radius: 0;
		-webkit-appearance: none;
		border-right: 0;
		background: inherit;
		font: 400 12px / 1 $font--text;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		color: $color--bright;

		&::placeholder {
			font: 400 12px / 14px $font--text;
			color: $color--bright; }

		&::-moz-placeholder { /* Firefox 19+ */
			font: 400 12px / 24px $font--text;
		}

		&:focus { outline: none; }
	}

	&--services &__input { background-color: $color--navy; }
	&--footer &__input { background-color: $color--dark; }

	&__submit {
		width: 100px;
		padding: 9px 0;
		border: 1px solid $color--red;
		background: inherit;
		font: 400 12px / 1 $font--text;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		color: $color--red;
		cursor: pointer; }

	&--services &__submit { background-color: $color--navy; }
	&--footer &__submit { background-color: $color--dark; }
}
