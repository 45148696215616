// ----------------------------------------
// Hero

.latest {
	width: 100%;
	background-image: pic('homepage-hero.jpg');
	background-size: cover;
	background-position: center center;

	&__add-wrapper {
		display: none;
		padding: 37px 0;
		text-align: center;

		@include media('>=md') { display: block; }
	}

	&__add { max-width: 100%; }

	&__title {
		padding: 13px 0;
		font: 400 49px / 1 $font--head;
		text-transform: uppercase;
		text-align: center;
		color: $color--red;

		@include media('>=md') {
			margin: 16px;
			padding: 0;
			font: 400 80px / 1 $font--head; }
	}

	&__title-less-important {
		display: none;

		@include media('>=md') { display: inline; }
	}

	&__group {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 28px 0 28px 0;

		@include media('>=md') { padding: 75px 0 28px 0; }

		@include media('>=xl') {
			flex-direction: row;
			align-items: stretch;
			justify-content: space-between; }
	}
}
