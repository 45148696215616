// ----------------------------------------
// Tally

.tally {
	margin-bottom: 14px;

	&:first-of-type {
		@include media('>=xl') { margin-top: 20px; }
	}

	&__title {
		display: flex;
		align-items: center;
		padding: 18px;
		font: 900 22px / 28px $font--text;
		color: $color--dark;
		background-color: $color--white;

		@include media('>=md') {
			padding: 18px 35px 28px 35px;
			font: 900 35px / 42px $font--text; }
	}

	&__title-image {
		display: inline-block;
		max-height: 28px;
		margin-right: 12px;

		@include media('>=md') {
			max-height: 45px;
			margin-right: 18px;
			position: relative;
			top: 4px; }
	}

	&__list {
		padding: 0 18px 17px 18px;
		background-color: $color--navy;
		letter-spacing: 0.03em;

		@include media('>=md') { padding: 0 35px 17px 35px; }
	}

	&__item {
		padding: 10px 0;

		& + & { border-top: 1px solid $color--blue; }
	}

	&__item-title {
		margin: 9px 0;
		font: 400 12px / 16px $font--text;
		color: $color--brightest;

		@include media('>=md') { font: 400 16px / 19px $font--text; }
	}

	&__sublist {
		margin: 8px 0 2px 0;
		display: flex;
		flex-direction: column;

		@include media('>=md') { flex-direction: row; }
	}

	&__subitem {
		flex: 0 1 33%;
		display: flex;
		align-items: center;
		min-height: 26px;
		font: 400 12px / 15px $font--text;
		color: $color--red;

		@include media('>=md') { font: 400 16px / 19px $font--text; }
	}

	&__subitem-icon {
		flex: 0 0 31px;
		display: inline-block;
		width: 31px;
		height: 26px;
		margin-right: 7px; }
}
