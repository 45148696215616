// ----------------------------------------
// Simple button

.simple-btn {
	display: inline-block;
	padding: 9px 20px;
	border: 1px solid $color--red;
	font: 400 12px / 13px $font--text;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	white-space: nowrap;
	color: $color--red;
	@include transition;

	&:hover {
		background-color: $color--red;
		color: $color--white; }

	&--white {
		border-color: $color--white;
		color: $color--white;

		&:hover {
			background-color: $color--white;
			color: inherit; }
	}

	&--mobile-block {
		display: block;
		text-align: center;

		@include media('>=md') {
			display: inline-block;
			text-align: inherit;
		}
	}
}
