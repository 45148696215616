// ----------------------------------------
// Tablet navigation bar

.cl-navbar {
	display: none;

	@include media('>=md') {
		display: flex;
		justify-content: space-between;
		background-color: $color--white;
		position: relative;
		z-index: 20; }

	&::after {
		display: block;
		width: 100%;
		height: 100%;
		content: '';
		background-color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1; }

	&__primary {
		display: flex;
		align-items: center; }

	&__secondary { display: flex; }

	&__logo-wrapper {
		display: flex;
		align-items: center;
		margin: 0 27px; }

	&__logo {
		display: inline-block;
		width: 45px;
		height: 38px;
		overflow: hidden;

		&::before {
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background-image: brand('brandmark.svg');
			background-size: contain; }
	}

	&__elem {
		position: relative;

		&::after {
			display: inline-block;
			width: 1px;
			height: 25px;
			content: '';
			background-color: $color--brightest;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%); }
	}
}
