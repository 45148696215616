// ----------------------------------------
// Subnav

.xl-subnav {
	display: none;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: -45px;

	&:hover { display: block; }

	&::before {
		display: block;
		width: 100%;
		height: 200px;
		content: '';
		background-color: $color--red;
		transform: skewY(-1deg);
		position: absolute;
		top: -160px;
		left: 0;
		z-index: -1; }

	&--inverse::before {
		transform: skewY(1deg);
		top: -150px; }

	&__list {
		display: flex;
		align-items: center;
		height: 100%;
		margin-left: 30px; }

	&__item { margin-right: 55px; }

	&__link {
		font: 300 20px / 1 $font--text;
		letter-spacing: 0.03em;
		color: $color--white; }
}
