// ----------------------------------------
// Hot

.hot {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: 436px;
	background-image: icon('hockey-player.svg');
	background-size: cover;
	background-position: center center;
	position: relative;

	@include media('>=md') { height: 622px; }

	&::before {
		display: block;
		width: 100%;
		height: 100%;
		overflow: hidden;
		content: '';
		background-image: pic('hockey-players.jpg');
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1; }

	&__content {
		padding: 18px 16px 28px 16px;
		font: 400 12px / 16px $font--text;
		color: $color--brightest;
		position: relative;
		z-index: 2;

		@include media('>=md') {
			padding: 18px 20% 30px 28px;
			font: 400 16px / 22px $font--text; }

		p { margin: 10px 0; }
	}

	&__time {
		font: 300 12px / 1 $font--text;
		letter-spacing: 0.05em;
		color: $color--bright; }

	&__title {
		margin-top: 8px;
		font: 900 35px / 40px $font--text;
		color: $color--red;

		@include media('>=md') { font: 900 56px / 60px $font--text; }
	}

	&__author {
		font: 300 12px / 1 $font--text;
		color: $color--bright; }

	&__share {
		font-size: 0;
		position: absolute;
		top: 28px;
		right: 28px;
		z-index: 3;

		&::before {
			display: inline-block;
			width: 31px;
			height: 31px;
			overflow: hidden;
			margin-left: 6px;
			content: '';
			background-image: icon('share--white.svg');
			background-size: 100% 100%; }

		@include media('<lg') {
			&::after { display: none; }
		}

		@include media('>=lg') {
			font: 400 12px / 1 $font--text;

			&::before {	display: none; }
		}
	}
}
