// ----------------------------------------
// Search bar in navbar

.sm-search {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 51px;
	height: 53px;
	background-color: $color--white;

	&__form {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 11;

		&--open {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: 100%;
			background-color: $color--brightest; }
	}

	&__label { display: none; }

	&__input {
		width: calc(100% - 76px);
		padding: 0 15px;
		border: none;
		background-color: $color--brightest;
		font: 300 14px / 20px $font--text;
		color: #353535;

		&:focus { outline: none; }

		&::placeholder {
			font: 300 14px / 20px $font--text;
			color: #8e8e8e; }
	}

	&__close-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 51px;
		height: 53px;

		svg {
			width: 16px;
			height: 16px; }

		svg * {
			fill: $color--red;
			@include transition(fill); }

		&:hover svg * { fill: $color--dark; }
	}

	&__loupe {
		display: inline-block;
		width: 18px;
		height: 18px;
		overflow: hidden;

		svg {
			display: block;
			width: 100%;
			height: 100%; }

		svg * {
			fill: #353535;
			@include transition(fill); }

		&:hover svg * { fill: $color--red; }
	}
}
